import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './documenti.module.css'
import Layout from '../components/layout'
import { Grid, Typography } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

let PDFViewer = () => <div />;

const Documenti = (props) => {
  const documenti = get(props, 'data.allContentfulDocumento.edges').map(
    (documento) => documento.node.documento
  )
  const pageTitle = 'Movimento Rivoluzione Fiscale - Documenti'

  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    PDFViewer = require('pdf-viewer-reactjs');
    setHasMounted(true);
  }, []);

  return (
    <Layout location={props.location}>
      <Helmet title={pageTitle}>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.gridContainer}>
          <Grid container spacing={2} className={styles.documentsContainer}>
            <Grid item xs={12} className={styles.titleContainer}>
              <Typography variant={'h3'}>{pageTitle}</Typography>
            </Grid>
            {documenti.map((documento) => (
              <Grid item xs={12} key={documento.title}>
                <div
                  className={styles.documentContainer}
                  onClick={() => window.open(documento.file.url)}
                >
                  <PictureAsPdfIcon className={styles.icon} />
                  <Typography variant={'body1'}>{documento.title}</Typography>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} className={styles.titleContainer}>
              <Typography variant={'h3'} style={{marginTop: 30}}>Statuto</Typography>
            </Grid>
            <Grid item xs={12}>
              {
                hasMounted && (<PDFViewer
                  hideZoom
                  hideRotation
                  rotationAngle={-90}
                  canvasCss={styles.pdfContainer}
                  document={{
                    url: 'https://downloads.ctfassets.net/mliqjt4giyw6/1Sn2vSuJSR8nZreL3Eanmh/6083cc767ee3cf8d2a222e117fd9f42a/ATTO_COSTITUTIVO_-MRF-NOTAIO_BERTONCINO.pdf',
                  }}
                />)
              }
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}

export default Documenti

export const pageQuery = graphql`
  query BlogDocumenti {
    allContentfulDocumento {
      edges {
        node {
          documento {
            id
            description
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
